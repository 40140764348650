import React from "react"
import { connect, useSelector } from "react-redux"

import { CareersQuery } from "../../prismic/staticQueries"
import Layout from "../../layout"
import {ContentBasic} from "../../components/common"

const Careers = () => {
  const language = useSelector(state => state.language)
  const careersPageData = CareersQuery(language)
  return (
    <>
      <Layout>
        <ContentBasic  {...careersPageData} show_stats={false} breadCrumbBanner={false} />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(Careers)
